import React, { useContext } from 'react';
import { Store } from '../store/context';
import { Link } from 'react-router-dom';

const WallCard = ({ id, name }) => {
  const { deleteWall } = useContext(Store);

  return (
    <Link to={`/wall/${id}`}>
      <div className="flex flex-col p-4 bg-white border border-gray-300 rounded-lg shadow-md">
        <h2 className="mb-4 text-xl font-semibold">{name}</h2>
        <div className="flex justify-end">
          <button className="mr-2 text-blue-500 hover:text-blue-700">
            Edit
          </button>
          <button
            className="text-red-500 hover:text-red-700"
            onClick={() => deleteWall(id)}
          >
            Delete
          </button>
        </div>
      </div>
    </Link>
  );
};

export default WallCard;
