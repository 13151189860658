import { useState, useEffect, useContext } from "react";
import WallModule from "./WallModule";
import { Store } from "../store/context";
import { Link } from "react-router-dom";

const Title = () => {
  return <a href="/"><h1 className="text-2xl font-bold text-red-600">Toddle</h1></a>;
};

const Header = ({ onSearch, onCreate }) => {
  const { showcreate, setShowcreate } = useContext(Store);
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSearch = (searchText) => {
    onSearch(searchText);
  };

  const handleCreate = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      handleSearch(searchText);
    }, 300); // Debounce delay of 300ms

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchText]);

  return (
    <>
      <div className="flex justify-between p-6 shadow-lg">
        <Link to='/' onClick={()=>setShowcreate(true)}>
          <Title />
        </Link>
        <div className="flex">
          <div className="mx-2">
            <input
              className="border rounded-md outline-none"
              type="text"
              placeholder="🔍 Search here..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          {showcreate && (
            <div className="mx-2">
              <button
                className="pt-1 pb-1 pl-3 pr-3 text-white bg-red-700 rounded-lg"
                onClick={handleCreate}
              >
                + Create new board
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <WallModule isOpen={showModal} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};

export default Header;
