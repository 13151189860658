

const Footer = () => {
  return (
    <div>
    <hr className="w-4 mt-36"/>
      <h1 className="flex justify-center m-10 text-2xl font-bold">Footer</h1>
    </div>
  )
}

export default Footer;
